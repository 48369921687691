import config from '@haaretz/l-config';
import fork from '@haaretz/l-fork.macro';

import type { HtzAppConfig } from '@haaretz/l-app-config/types';
import type { ConnectionType, Env, PlatformType } from '@haaretz/s-types';
import type { LinkProps as NextLinkProps } from 'next/link';
export type Url = NextLinkProps['href'];

export const supportedPageTypes: HtzAppConfig<Env, ConnectionType>['supportedNextUrl'] =
  config.get('supportedNextUrl');

export default function isNextLink(href: Url, platform: PlatformType): boolean {
  // HACK: Its a temporary solution to avoid using client navigation in the native applications except Haaretz app
  if (fork({ default: true, tm: false }) && config.get('env') === 'prod' && platform === 'app') {
    return false;
  }

  const stringHref = typeof href === 'string' ? href : href.href;
  // 1. External URL are always non-Next.Js
  if (!stringHref || stringHref.startsWith('http')) return false;

  return supportedPageTypes.some(supportedPage =>
    supportedPage === '/' ? stringHref === '/' : stringHref.includes(supportedPage)
  );
}
